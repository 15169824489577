import api from '@/tools/api-tools'

const payTypeIcon = new Map([
  [1, '//img.laoban100.com/weblink/mobile/paytype/84/wechat.png'],
  [4, '//img.laoban100.com/weblink/mobile/paytype/84/storecard.png'],
  ['default', '']
])

/**
 * 小程序定单数据模型
 * @author 张晓东
 */
export default class MiniProgramOrder {
  /**
   * 条件查询并分页显示小程序列表数据
   * @param params 查询的条数参数 {querymode: '', keyword: '', start: 0, limit: 10, status: null, dateQuery: {}, payType: '',transportType: ''}
   * @returns {Promise} 处理数据后的Promise对象
   */
  static getOrderListByPage (params) {
    // params.keyword = encodeURIComponent(params.keyword)
    return api.post('//msc-api.laoban100.com/orders-management/orders/list', params).then(res => {
      const data = res.data
      data.items = data.items.map(item => {
        item.hasMoreOrder = item.orderGoods.length > 1
        item.expandedRows = true // 是否展开显示
        item.singleDiscountRate = item.orderGoods[0].discountRate
        item.goodsMaxImage = item.orderGoods[0].picture
        item.totalQuantity = item.orderGoods.reduce((acc, cur) => { // 商品总数
          return acc + cur.quantity
        }, 0)
        item.payTypeIconUrl = payTypeIcon.get(item.payType) || payTypeIcon.get('default')
        return item
      })
      return data
    })
  }
}
