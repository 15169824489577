/**
 * 小程序授权相关
 */
import { apiUrl, mscUrl, truckApiUrl } from '@/config/net'
import api from '@/tools/request'

/**
 * 获取小程序是否开通
 */
export function fetchMiniProgramOpenStatus () {
  return api.get(`${truckApiUrl}/member-introduction-config/have-miniprogram-permission`).then(json => json)
}

/**
 * 获取小程序的审核状态
 */
export function fetchMiniProgramAuditStatus () {
  return api.get(`${apiUrl}/mobilecase/minprogram-state`).then(json => json)
}

/**
 * 获取快速注册小程序的状态
 */
export function fetchMiniProgramQuickyRegistStatus () {
  return api.get(`${mscUrl}/miniapp-fastregister/state`).then(json => json)
}

/**
 * 获取微信授权的地址
 */
export function fetchAuthLink () {
  return api.get(`${apiUrl}/mobilecase/authorizaton-link`).then(json => json)
}
