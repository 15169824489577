/*
 * 小程序数据分析
 */
import store from '@/store'
import api from '@/tools/request'
import Dayjs from 'dayjs'
import { mscUrl, apiUrl } from '@/config/net'
class MiniProgramAnalyze {
  constructor () {
    this.chartData = {}
    this.lineData = []
  }

  getAnalyzeData () {
    const accId = store.state.userInfo.accId
    return api.get(`${mscUrl}/orders/order-success-trend?accountId=${accId}`).then(json => {
      return this.chartOrderDataStruct(json) || []
    })
  }

  schemaListData (data) {
    const schemaData = []
    data.forEach(item => {
      const schemaDataObj = {}
      schemaDataObj.avatarUrl = item.avatarUrl
      schemaDataObj.city = item.city
      schemaDataObj.createAt = item.createAt
      schemaDataObj.gender = item.gender
      schemaDataObj.nickName = item.nickName
      schemaDataObj.province = item.province
      schemaDataObj.remark = item.remark
      schemaDataObj.openId = item.openId
      schemaDataObj.country = item.country
      schemaDataObj.totalOrderMoney = item.totalOrderMoney
      schemaDataObj.lastOrderMoney = item.lastOrderMoney
      schemaDataObj.phone = item.phone
      schemaDataObj.totalVisitTimes = item.totalVisitTimes
      schemaDataObj.memberId = item.memberId
      schemaData.push(schemaDataObj)
    })
    return schemaData
  }

  chartOrderDataStruct (data) {
    const chartData = data.map(item => {
      return {
        currentDate: item.date,
        saleNums: item.saleMoney
      }
    })
    // return chartData.reverse()
    return chartData
  }

  getWXMemberOrderList (currentPage, pageSize) {
    const accId = store.state.userInfo.accId
    const params = {
      accountId: accId,
      CurrentPage: currentPage,
      PageSize: pageSize
    }
    return api.post(`${mscUrl}/orders/miniapp-authorization-members`, params).then(json => {
      const data = json
      return {
        currentPage: data.currentPage,
        pageSize: data.pageSize,
        totalCounts: data.totalCounts,
        totalPages: data.totalPages,
        items: this.schemaListData(data.items)
      }
    })
  }

  // 获取每日订单概况
  getStoreOrderInfo () {
    const accId = store.state.userInfo.accId
    return api.get(`${mscUrl}/order-member-summary?accountId=${accId}`).then(json => {
      return json
    })
  }

  // 获取会员、访客、订单summary
  getMembersAndOrderSummary () {
    return api.get(`${mscUrl}/orders-management/orders/members-and-ordersales-summary`).then(json => {
      return json.data
    })
  }

  getStoreDailyTendency () {
    const accId = store.state.userInfo.accId
    const today = Dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    return api.get(`//msc-bridge.laoban100.com/analysis/daily-summary?accountId=${accId}&datetime=${today}`).then(json => {
      return json.data
    })
  }

  getMiniProgramQRCode () {
    return api.get(`${apiUrl}/mobilecase/miniapp-qrcode`).then(json => {
      return json
    })
  }

  // 总订单数成交笔数
  getTotalOrderInfo () {
    const accId = store.state.userInfo.accId
    return api.get(`${mscUrl}/orders/miniapp-order-dashboard?accountId=${accId}`).then(json => {
      return json
    })
  }
}

export default MiniProgramAnalyze
