<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar v-if="appVersionInfo.isShowNavBar"
               title="小程序"
               @click-right="handleNavBarRightClick"
               :rightImageName="isOpenMiniProgram ? 'miniProgramSeting' : ''"
      >
        <template #right v-if="isOpenMiniProgram">
          <i class="iconfont2 icon-shezhi" />
        </template>
      </nav-bar>
    </template>
    <van-notice-bar v-if="auditingAlert" text="您的小程序正在微信官方审核，预计1-3个工作日，期间请务进行任何修改，以免审核失败，给您造成不便！" left-icon="volume-o" />
    <scroll-view height="fullView" ref="scroll" pullup @scrollToEnd="loadMoreMember()">
      <div class="margin-lr-30 nav-box">
        <div class="nav-item ds-flex align-center"
             @click="toNative('goodsGrounding')"
        >
          <div class="icon-box icon-orange mb-5 ds-flex align-center"><i class="iconfont2 icon-CombinedShape1 nav__icon receive-icon"></i></div>
          <div class="nav-title">货品上架</div>
        </div>
        <div class="nav-item ds-flex align-center"
             @click="toNative('order')"
        >
          <div class="icon-box icon-yellow ds-flex mb-5  align-center"><i class="iconfont2 icon-icon-test nav__icon ranking-icon"></i></div>
          <div class="nav-title">订单管理</div>
        </div>
        <div class="nav-item ds-flex align-center"
             @click="dialogVisible = true"
        >
          <div class="icon-box icon-primary ds-flex mb-5  align-center"><i class="iconfont2 icon-huiyuan21 nav__icon"></i></div>
          <div class="nav-title">网店顾客</div>
        </div>
        <div class="nav-item ds-flex align-center"
             @click="dialogVisible = true"
        >
          <div class="icon-box icon-ching ds-flex mb-5  align-center"><i class="iconfont2 icon-zhuangxiu nav__icon"></i></div>
          <div class="nav-title">网店装修</div>
        </div>
      </div>
      <!-- 概况 -->
      <div class="margin-lr-30 summary-money-box">
        <div class="wrapper">
          <p class="summary-title">累计交易金额</p>
          <div class="summary-money">
            <i class="summary-symbol">￥</i>
            <span class="money">{{ totalOrderInfo.totalSaleMoney || 0.00 }}</span>
          </div>
        </div>
      </div>
      <!-- 概述详情 -->
      <div class="detail-box ds-flex mb-20">
        <div class="detail__item detail-success">
          <div class="item__top text-center">{{ vistSummary.todayMemberAuthorizationCount || 0 }}</div>
          <div class="item__title text-center tex-color grayfang">今日访客</div>
          <div class="item__date text-center">
            <span class="date__title">昨天</span>
            <span class="date__num">
              +{{ vistSummary.yesterdayMemberAuthorizationCount || 0 }}
            </span>
          </div>
        </div>
        <div class="detail__item detail-primary">
          <div class="item__top text-center">{{ newVipSummary.newMemberSummary || 0 }}</div>
          <div class="item__title text-center">新会员</div>
          <div class="item__date text-center">
            <span class="date__title">昨天</span>
            <span class="date__num">+{{ newVipSummary.yesterdayNewMemberCount || 0 }}</span>
          </div>
        </div>
        <div class="detail__item detail-warning">
          <div class="item__top text-center">{{ orderSummary.todaySaleQuantity || 0 }}</div>
          <div class="item__title text-center">成交笔数</div>
          <div class="item__date text-center">
            <span class="date__title">昨天</span>
            <span class="date__num">+{{ orderSummary.yesterdaySaleQuantity || 0 }}</span>
          </div>
        </div>
        <div class="detail__item detail-danger">
          <div class="item__top text-center">
            <span class="font-14">￥</span>{{ orderSummary.todaySaleAmount || 0.00 }}
          </div>
          <div class="item__title text-center">今日销售</div>
          <div class="item__date text-center">
            <span class="date__title">昨天</span>
            <span class="date__num">+{{ orderSummary.yesterdaySaleAmount || 0.00 }}</span>
          </div>
        </div>
      </div>
      <!-- 图表 -->
      <div class="margin-lr-30 chart-box">
        <div class="chart__title">近7天会员卡成交趋势</div>

        <div v-if="chartLoading" class="load__error">
          <van-loading>获取数据中</van-loading>
        </div>
        <chart class="chart" v-else :chartData="chartData" />
        <div v-if="chartError" @click="loadChartData" class="load__error">加载超时，点击请重试！</div>
      </div>
      <!-- 今日订单 -->
      <div class="mt-20">
        <div class="header ds-flex align-center"
             @click="showOrder = !showOrder"
        >
          <span class="text-color dark-gray font-small">今日订单</span>
          <span class="ml-20 text-color danger"><span class="scale-rmb inline-block text-bottom">&yen;</span>{{ orderSummary.todaySaleAmount || 0.00 }}</span>
          <span class="text-color gray scale-font text-bottom">（{{ orderSummary.todaySaleQuantity || 0 }}笔）</span>
          <i :class="[showOrder ? 'down' : 'up', 'arrow' ]"></i>
        </div>
        <div class="list-containner"
             v-show="showOrder"
        >
          <div class="member-item van-hairline--bottom"
               v-for="item in OrderList.items"
               :key="item.openId"
          >
            <div class="left-desc order-item">
              <div class="item-icon img-box">
                <avatar :border="false" :avatarImage="item.orderGoods[0].picture" />
              </div>
              <div class="ml-10 van-ellipsis item-name">
                <div class="van-ellipsis">
                  <span class="nick-name text-color dark-gray">{{ item.orderGoods[0].name+'*'+item.orderGoods[0].quantity }}</span>
                </div>
                <div class="text-color gray van-ellipsis bottom-text">
                  {{ item.phone | phoneFormat }}
                </div>
              </div>
            </div>
            <div class="right-desc ds-flex align-center">
              <div class="text-color danger">{{ '&yen;&nbsp;'+item.payMoney }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 小程序最近访客 -->
      <div class="mt-5">
        <div class="header ds-flex align-center"
             @click="showUv = !showUv"
        >
          <span class="text-color dark-gray font-small">小程序最近访客</span>
          <i :class="[showUv ? 'down' : 'up', 'arrow' ]"></i>
        </div>
        <div class="list-containner"
             v-show="showUv"
        >
          <div class="member-item van-hairline--bottom"
               v-for="item in memberListData.items"
               :key="item.openId"
          >
            <div class="left-desc">
              <div class="img-box">
                <avatar :border="false" class="img-box" :avatarImage="item.avatarUrl" shape="square" :avatarName="item.nickName" />
              </div>
              <div class="ml-10 van-ellipsis item-name">
                <div class="top-text van-ellipsis">
                  <span class="nick-name text-color dark-gray">{{ item.nickName }}</span>
                </div>
                <div class="text-color gray van-ellipsis bottom-text">
                  {{ item.phone | phoneFormat }}
                </div>
              </div>
              <div class="sex text-color gray font-small">{{ item.gender === 1 ? "男" : "女" }}</div>
            </div>
            <div class="ds-flex align-center text-color gray"
                 v-if="item.lastOrderMoney === 0 "
            >{{ item.phone.indexOf('未') > -1 ? '普通用户' : '未购买' }}</div>
            <div class="right-desc"
                 v-else
            >
              <div class="text-color danger van-ellipsis ">{{ '&yen;&nbsp;'+item.lastOrderMoney }}</div>
              <div class="bottom-text text-color gray font-small">自动录为会员</div>
            </div>
          </div>
          <scroll-view-loading :isFinished="isFinished" />
        </div>
      </div>
    </scroll-view>
    <template v-slot:other>
      <van-dialog v-model="dialogVisible">
        <img class="tip-image"
             src="../../assets/images/miniprogram/dialog-tip.png"
             alt=""
        >
        <div class="tip-containner">
          <!-- <p class="content text-center">工程师努力开发中</p> -->
          <p class="content text-center">登陆生意专家电脑端查看</p>
          <p class="content text-center link">www.shengyi.ai</p>
        </div>
      </van-dialog>
    </template>
  </page-view>
</template>
<script>
import appVersionMixins from '@/minxins/app-version-mixins'
import PageView from '@/layouts/PageView'
import ScrollView from '@/components/scroll-view'
import Chart from './modules'
import ScrollViewLoading from '@/components/scroll-view-loading'
import Avatar from '@/components/avatar'
import NavBar from '@/components/nav-bar'
// import dayjs from 'dayjs'
import { Divider, Loading, Image, Icon, Collapse, CollapseItem, Dialog, NoticeBar } from 'vant'
import AnalyzeModel from '@/model/miniprogram/analyze'
import MiniProgramOrder from '@/model/miniprogram/order'
import {
  fetchMiniProgramOpenStatus,
  fetchMiniProgramAuditStatus,
  fetchMiniProgramQuickyRegistStatus
} from '@/model/miniprogram/authority'
export default {
  name: 'MiniProgramSummary',
  mixins: [appVersionMixins],
  components: {
    PageView,
    ScrollView,
    ScrollViewLoading,
    Avatar,
    Chart,
    NavBar,
    [Divider.name]: Divider,
    [Loading.name]: Loading,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Dialog.Component.name]: Dialog.Component,
    [NoticeBar.name]: NoticeBar
  },
  props: {},
  data () {
    const analyzeModel = new AnalyzeModel()
    return {
      isOpenMiniProgram: false, // 是否开通小程序
      MiniProgramOrder,
      analyzeModel,
      chartLoading: true,
      chartError: false,
      totalOrderInfo: {}, // 订单总额汇总
      loading: false,
      isFinished: false,
      currentLength: 0,
      vistSummary: {}, // 访客汇总
      newVipSummary: {}, // 新会员汇总
      orderSummary: {}, // 订单成交笔数汇总
      chartData: [], // 表格数据
      dialogVisible: false, // 未开通功能提示
      showOrder: true, // 是否显示订单
      OrderList: [], // 订单列表
      showUv: true, // 小程序最近访客
      auditingAlert: false, // 授权模式审核中，显示的提示
      searchObj: {
        start: 0,
        limit: 100,
        queryMode: '',
        keyword: '',
        status: 0,
        dateQuery: {
          dateType: 'today',
          begin: '',
          end: ''
        },
        payType: '',
        transportType: ''
      },
      memberListData: { // 最近访客列表
        items: []
      },
      pageConfig: {
        pageIndex: 1,
        pageSize: 15,
        totalCount: 0
      }
    }
  },
  watch: {},
  created () { },
  async mounted () {
    // 获取小程序开通过状态
    await this.loadingMiniProgramStatus()
    this.getTotalOrderInfo()
    this.loadChartData()
    this.getMembersAndOrderSummary()
    this.getMemberOrderList()
    this.getOrderListByPage(this.searchObj)
  },
  destroyed () { },
  methods: {
    // 过滤数据格式
    fliterData (arr) {
      const obj = {}
      const newArr = arr.reduce(function (item, next) {
        // eslint-disable-next-line no-unused-expressions
        obj[next.openId] ? '' : obj[next.openId] = true && item.push(next)
        return item
      }, [])
      return newArr
    },
    async getOrderListByPage (params) {
      this.OrderList = await this.MiniProgramOrder.getOrderListByPage(params)
    },
    // 加载更多会员
    async loadMoreMember () {
      if (this.isFinished || this.loading) return
      this.loading = true
      const data = await this.analyzeModel.getWXMemberOrderList(this.pageConfig.pageIndex, this.pageConfig.pageSize)
      if (data.items.length) {
        this.isFinished = data.items.length < this.pageConfig.pageSize
        this.pageConfig.totalCount = data.totalCounts
        this.pageConfig.pageIndex += 1
        const incrementItems = data.items
        this.memberListData.items.push(...incrementItems)
        this.memberListData.items = this.fliterData(this.memberListData.items)
        this.loading = false
        // 数据加载完成之后要进行重新计算当前宽度
        this.$nextTick(() => {
          this.$refs.scroll && this.$refs.scroll.refresh()
        })
      }
    },
    /**
     * 获取小程序订单总和
     */
    async getTotalOrderInfo () {
      this.totalOrderInfo = await this.analyzeModel.getTotalOrderInfo()
    },
    /**
     * 加载饼图数据
     */
    async loadChartData () {
      try {
        this.chartError = false
        this.chartLoading = true
        const json = await this.analyzeModel.getAnalyzeData()
        this.chartData = json
        this.chartLoading = false
      } catch (error) {
        this.chartLoading = false
        this.chartError = true
      }
    },
    /**
     * 加载最近访客记录
     */
    async getMemberOrderList () {
      if (this.isFinished || this.loading) return
      this.loading = true
      const data = await this.analyzeModel.getWXMemberOrderList(this.pageConfig.pageIndex, this.pageConfig.pageSize)
      if (data.items.length) {
        this.isFinished = data.items.length < this.pageConfig.pageSize
        this.pageConfig.totalCount = data.totalCounts
        this.pageConfig.pageIndex += 1
        const incrementItems = data.items
        this.memberListData.items.push(...incrementItems)
        this.memberListData.items = this.fliterData(this.memberListData.items)
        this.loading = false
        // 数据加载完成之后要进行重新计算当前宽度
        this.$nextTick(() => {
          this.$refs.scroll && this.$refs.scroll.refresh()
        })
      }
    },
    // 获取会员和订单汇总
    async getMembersAndOrderSummary () {
      const data = await this.analyzeModel.getMembersAndOrderSummary()
      this.vistSummary = data.memberAuthorizationSummary
      this.newVipSummary = data.newMemberSummary
      this.orderSummary = data.orderSummary
    },
    /**
     * 通知客户端打开对应的页面，通过appBride
     */
    toNative (name) {
      if (!window.$bridge) return
      switch (name) {
        case 'goodsGrounding':
          window.$bridge.page.miniProgramGoodsGrounding()
          break
        case 'order':
          window.$bridge.page.miniProgramOrder()
          break
      }
    },
    /**
     * 加载小程序当前的状态，跳转合适的页面
     * @description 逻辑暂时修改为：没有开通小程序，也是直接进入列表页面。
     */
    async loadingMiniProgramStatus () {
      this.$toast.loading({
        message: '获取数据中',
        duration: 0
      })
      const isOpenMiniProgram = await fetchMiniProgramOpenStatus()
      this.isOpenMiniProgram = isOpenMiniProgram
      if (isOpenMiniProgram) {
        const NEEDAUDITCODE = 1
        const AUDITINGCODE = 2
        const miniProgramAuditStatusCode = await fetchMiniProgramAuditStatus()
        const quicklyRegisterStatusJson = await fetchMiniProgramQuickyRegistStatus()
        if (miniProgramAuditStatusCode === NEEDAUDITCODE && !this.checkQuicklyRegister(quicklyRegisterStatusJson)) { // 需要授权
          this.$router.replace('/mini-program-old-audit')
        } else if (miniProgramAuditStatusCode === AUDITINGCODE && !this.checkQuicklyRegister(quicklyRegisterStatusJson)) { // 审核中
          this.auditingAlert = true
        }
      } else { // TODO 没有开通小程序时怎么处理
      }
    },
    // 检测快速注册
    checkQuicklyRegister (quicklyRegisterStatusJson) {
      const { result, data } = quicklyRegisterStatusJson
      if (result && data.state !== null) {
        if (data.state === 2) { // 审核成功
          // 使用replace会导致你的下一个页面没有办法正常返回
          this.$router.replace('/min-program-verify')
        } else {
          this.$router.replace('/attestminiprogram')
        }
        return true
      }
      return false
    },

    handleNavBarRightClick () {
      window.$bridge && window.$bridge.page.miniProgramSetting()
    }
  },
  filters: {
    phoneFormat (msg) {
      return msg.replace(/^(.{3})(.*)(.{4})$/, '$1 $2 $3')
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
