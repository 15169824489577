<template>
  <div>
    <canvas id="J_miniprogramchart" class="chart-box" width="100%" height="100%"></canvas>
  </div>
</template>
<script>
import F2 from '@antv/f2'
export default {
  name: 'MiniProgramChart',
  mixins: [],
  components: {},
  props: {
    chartData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      chartRef: []
    }
  },
  computed: {},
  watch: {
  },
  created () { },
  mounted () {
    this.chartRef = new F2.Chart({
      id: 'J_miniprogramchart',
      pixelRatio: window.devicePixelRatio
    })
    const defs = {
      currentDate: {
        type: 'timeCat',
        mask: 'MM/DD',
        tickCount: 7,
        range: [0, 1]
      },
      saleNums: {
        tickCount: 5,
        min: 0,
        alias: '销售额度'
      }
    }
    this.chartRef.source(this.chartData, defs)

    this.chartRef.axis('currentDate', {
      label: function label (text, index, total) {
        const textCfg = { fill: '#999999' }
        if (index === 0) {
          textCfg.textAlign = 'left'
        } else if (index === total - 1) {
          textCfg.textAlign = 'right'
        }
        return textCfg
      }
    })

    this.chartRef.axis('saleNums', {
      label: function label (text, index, total) {
        const textCfg = { fill: '#999999' }
        return textCfg
      }
    })
    this.chartRef.tooltip({
      showCrosshairs: true
    })
    this.chartRef.line().position('currentDate*saleNums').color('#FF6A3C').shape('smooth')
    this.chartRef.point().position('currentDate*saleNums').color('#FF6A3C').shape('smooth')
      .style({
        stroke: '#fff',
        lineWidth: 1
      })
    this.chartRef.render()
  },
  destroyed () { },
  methods: {}
}
</script>
<style lang="less" scoped>
.chart-box {
  width: 345px;
  height: 170px;
}
</style>
