import Axios from 'axios'
import debounce from 'lodash.debounce'
import { Toast } from 'vant'
import { apiTimeout } from '../config/net'
import { getCookie } from './cookie'

// loading对象
let loading
// 当前正在请求的数量
let needLoadingRequestCount = 0
// 显示loading
function showLoadingFun (target) {
  // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
  // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
  if (needLoadingRequestCount === 0 && !loading) {
    loading = Toast({
      type: 'loading',
      message: '加载中',
      getContainer: target || 'body',
      duration: 0
    })
  }
  needLoadingRequestCount++
}

// 隐藏loading
function hideLoading () {
  needLoadingRequestCount--
  needLoadingRequestCount = Math.max(needLoadingRequestCount, 0) // 做个保护
  if (needLoadingRequestCount === 0) {
    // 关闭loading
    toHideLoading()
  }
}

// 防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
var toHideLoading = debounce(() => {
  loading.clear()
  loading = null
}, 300)

// 获取TOKEN
function getToken () {
  let token
  if (process.env.NODE_ENV === 'development') {
    token = 'eyJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50SWQiOjM5OTExMywib3BlcmF0b3JJZCI6NDQ1NzU4LCJ0aW1lc3RhbXAiOiIxNjcyMTkyMzA5Njg5IiwibW9kZSI6MSwic291cmNlIjoiV0IiLCJ0dGwiOjB9.MVZKOCkjMfoGFyN6Dijzpf-AQ6JtQr0iC_uhhF42-YM'
  } else {
    token = getCookie('token') || getCookie('i200_token')
  }
  return token
}

// 创建实例
const serveice = Axios.create({
  timeout: apiTimeout
})

// 错误处理
const err = (error) => {
  const { headers: { showLoading } = { showLoading: false } } = error || {}
  if (showLoading) {
    hideLoading()
  }
  if (error.response) {
    if (error.response.status === 400) {
      Toast({
        type: 'text',
        message: `生意专家暂时没有办法识别，您输入的信息！`,
        duration: 2000
      })
    }
  }
  return Promise.reject(error)
}

// 请求拦截器
serveice.interceptors.request.use(config => {
  const token = getToken()
  const { headers: { showLoading } = { showLoading: false } } = config || {}
  if (token) {
    config.headers.token = token
  }
  if (showLoading) { // 显示loading
    showLoadingFun()
  }
  return config
}, err)

// 响应拦截
serveice.interceptors.response.use(response => {
  const data = response.data
  const { headers: { showLoading } = { showLoading: false } } = response.config || {}
  if (showLoading) {
    hideLoading()
  }
  if (data.code === 400) { // 服务器内部出错
    return Promise.reject(data)
  } else if (data.code === 8005) {
    Toast({
      type: 'text',
      message: `${data.message}`
    })
    return Promise.reject(data)
  }
  return data
}, err)

export default {
  get (url, config) {
    const { headers: { bridge, showLoading } = { bridge: false, showLoading: false } } = config || {}
    // 能过与原生的桥发送数据
    if (bridge && process.env.NODE_ENV !== 'development') {
      return new Promise(resolve => {
        if (showLoading) {
          showLoadingFun()
        }
        window.$fe_bridge.session.send('get', url, {}, res => {
          if (showLoading) {
            hideLoading()
          }
          resolve({ data: res })
        })
      })
    }
    return serveice.get(url, config)
  },
  post (url, data, config) {
    const { headers: { bridge, showLoading } = { bridge: false, showLoading: false } } = config || {}
    // 能过与原生的桥发送数据
    if (bridge && process.env.NODE_ENV !== 'development') {
      return new Promise(resolve => {
        if (showLoading) {
          showLoadingFun()
        }
        window.$fe_bridge.session.send('post', url, data, res => {
          if (showLoading) {
            hideLoading()
          }
          resolve({ data: res })
        })
      })
    }
    return serveice.post(url, data, config)
  },
  delete (url, config) {
    return serveice.delete(url, config)
  },
  put (url, data, config) {
    return serveice.put(url, data, config)
  }
}
